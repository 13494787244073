import { Container, Modal } from 'react-bootstrap'
import React, { setGlobal, useEffect, useGlobal, useState } from 'reactn'
import { View } from '../../utils/styles'
import Portfolio from '../portfolio'
import Templates from '../templates'

function Main ({ match }) {
  const [doc, setDoc] = useState(null)
  const [modalVisible] = useGlobal('modalVisible')
  const [website] = useGlobal('website')

  const urlResolver = () => {
    if (website && website.content) {
      for (var id in website.content.results) {
        if (website.content.results[id].slugs.includes('portfolio')) {
          return setDoc(website.content.results[id])
        } else {
          // return history.push('/')
        }
      }
    }
  }

  const Media = () => {
    return modalVisible ? (
      <Modal show={modalVisible != null} className='media' on>
        <Modal.Header closeButton onClick={(e) => setGlobal({ modalVisible: null })}>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalVisible.type === 'img' && <img src={modalVisible.src}
            width={'100%'}
            height={'100%'} />}
          {modalVisible.type === 'video' &&
            <div class='videowrapper' style={{ overflow: 'hidden' }}>
              <iframe src={modalVisible.src} rel={0} loop={1} autoplay={1}
                frameborder='0' allowfullscreen
                width={'100%'}
                height={480} />
            </div>}
        </Modal.Body>
      </Modal>
    ) : null
  }

  useEffect(() => {
    !doc && urlResolver()
  }, [website])

  return (
    <Templates>
      <Container fluid className='rm-p'>
        <View overflow>
          {Media()}
          {doc ? <Portfolio item={doc} type={'portfolio'} />
            : <View bgc={process.env.REACT_APP_WEBSITE_COLOR} minHeight={window.innerHeight} center flex>
              <img height={150} src={require('../../assets/loader.svg')} alt='loader' />
            </View>}
        </View>
      </Container>
    </Templates>
  )
}
export default Main
