import { init, appReducers } from './app'
import { payments, paymentReducers } from './payments'

export const reducerParams = {
  init,
  payments
}

export const instantiateReducers = () => {
  const combineReducers = [
    appReducers(),
    paymentReducers()

  ]

  combineReducers.forEach(reducerFunction => {
    return reducerFunction
  })
}
