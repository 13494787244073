import { addReducer } from 'reactn'

export const payments = {
  classType: null,
  amount: null,
  userData: {},
  params: null,
  loading: false,
  isActive: false,
  isModal: false
}

export const paymentReducers = () => {
  addReducer('resetPayments', (global, dispatch) => ({ payments }))
  addReducer('setPayment', (global, dispatch, data) => ({ payments: { ...global.payments, ...data } }))
}
