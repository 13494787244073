import { RichText } from 'prismic-reactjs'
import React from 'reactn'
import getColor from '../utils/getColor'
import { H1, Link, View } from '../utils/styles'

function Main ({ item }) {
  return (
    <View bgc={item.subcolor} center className='center' mt={30}>
      {<H1 size={2.5} bold={400} textCenter color={item.subinversed === 'true' ? 'white' : 'dark'} pt={50} > {RichText.asText(item.subname)}</H1>}
      {item.submenu.length > 0 && item.submenu[0].text !== '' && item.sublink &&
        <Link center href={item.sublink.slug} bgc={getColor(item.subinversed, false, item.subcolor)} boxShadow={item.subcolor} color={getColor(item.subinversed, true, '#000')} pt={15} pb={15} pr={40} mb={40} pl={40} size={1.2} bold={300} buttonRadius='8px' textCenter block width={180}> {RichText.asText(item.submenu)}</Link>}
    </View>
  )
}
export default Main
