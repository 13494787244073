import { Col, Row } from 'react-bootstrap'
import React, { useEffect, useGlobal, useState } from 'reactn'
import { Text, View } from '../../utils/styles'
import { uniq, sortBy } from 'lodash'
import Lottie from 'lottie-react-web'
import { isMobile as m } from 'react-device-detect'

const Footer = ({ match }) => {
  const [doc, setDoc] = useState({ website: null, menu: null })
  const [website] = useGlobal('website')

  const prepMenu = (data, type = 'name_') => {
    let name = []
    if (data) {
      data.map(item => {
        if (item.data.type === 'footer_menu' || item.data.type === 'menu' || item.data.type === 'portfolio') {
          item.tags.map(e => {
            if (e.startsWith(type)) { name.push(e.replace(type, '')) }
          })
        }
      })
    }
    return uniq(name)
  }

  const getTagName = (item, type = 'name_') => {
    let name
    item.tags.map(e => {
      if (e.startsWith(type)) { name = e.replace(type, '') }
    })
    return name.replace('_', ' ') || 'Untitled'
  }

  const urlResolver = () => {
    if (website && website.content && !doc.website) {
      return setDoc({
        website: website.content.results,
        menu: prepMenu(website.content.results)
      })
    }
  }

  const content = () => {
    if (doc.website) {
      return doc.menu.map((menu, i) =>
        <Col key={i} lg={2} sm={6} md={3} xs={6} style={{ display: m && i === 0 ? 'none' : 'block' }}>
          <Text size={1.2} bold={500} >{menu.replace('_', ' ')}</Text>
          <View mt={20}>
            <ul>
              {sortBy(doc.website, [(o) => -Number(o.data.position)]).map((item, index) => {
                return (item.data.type === 'footer_menu' || item.data.type === 'portfolio' || item.data.type === 'menu') && getTagName(item) === menu.replace('_', ' ')
                  ? <li key={index}>
                    {!item.data.image.url && <a href={item.slugs[0]} bold={300}>
                      <span>{item.data.menu[0].text}</span>
                    </a>}
                    {item.data.image && item.data.image.url &&
                    <Col lg={3} sm={3} md={3} xs={3} className={'rm-p'}>
                      <View mb={5}><img src={item.data.image.url} alt={item.data.image.alt} style={{ width: m ? '80px' : '120px' }} /></View>
                    </Col>}
                  </li> : null
              }
              )}
            </ul>
          </View>
        </Col>)
    }
    return null
  }

  useEffect(() => {
    urlResolver()
  }, [website])

  return (
    <View id='footer' pt={50} pb={150} sm={50} pl={100} pr={100}>
      <div>
        <Row>
          {website && website.site.results.length > 0 &&
          <Col lg={6} md={6} xs={12} sm={12} >
            {website.site.results[0].data.logo.url.endsWith('.json')
              ? <View p={50} style={{ width: m ? '200px' : '200px' }} ><Lottie options={{
                loop: false,
                autoplay: true,
                path: website.site.results[0].data.logo.url }} /></View>
              : <img src={website.site.results[0].data.logo.url} alt='logo' style={{ height: m ? '35px' : '60px' }} />}
            <Text size={0.8} bold={300} mt={20} mb={100} block>{process.env.REACT_APP_WEBSITE_NAME}</Text>
            <br />
          </Col>}
          {content()}
        </Row>
      </div>
    </View>

  )
}
export default Footer
