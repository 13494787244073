import { H1, RichText } from 'prismic-reactjs'
import Collapsible from 'react-collapsible'
import { isMobile as m } from 'react-device-detect'
import { Minus, Plus } from 'react-feather'
import React, { useState } from 'reactn'
import getColor from '../utils/getColor'
import { Link, Text, View } from '../utils/styles'

function Main ({ item }) {
  const [index, setIndex] = useState(null)

  const onOpen = (e) => {
    setIndex(e)
  }
  const el = (e, i) => {
    return (
      <View>
        <Text sm={1}>{e}</Text>
        {index === i ? <Minus style={{ float: 'right' }} /> : <Plus style={{ float: 'right' }} /> }
      </View>
    )
  }
  return (
    <View ml={m ? 20 : 100} mr={m ? 20 : 100} mt={0} mb={0} center> 
      <View block center>
        {item.subtitle && item.subtitle.length > 0 && <H1 size={1.5} textCenter bold={400} pt={40} color={getColor(item.subinversed, false, item.subcolor)} pb={10}>
          {RichText.asText(item.subtitle)}
        </H1>}
        <Collapsible tabIndex={0} trigger={el(RichText.asText(item.subname), 0)} triggerTagName='h5' onClosing={(e) => onOpen(null)} onOpening={(e) => onOpen(0)}>
          <View mt={50}>
            {<Text block >
              <RichText render={item.subdescription} />
            </Text>}
            <Link href={item.sublink.url} bg='dark' color='white' p={10} pr={40} pl={40} mt={30} size={1} bold={400} center block left buttonRadius>{RichText.asText(item.submenu)}</Link>
          </View>
        </Collapsible>
      </View>

    </View>
  )
}
export default Main
