import { addReducer } from 'reactn'

export const init = {
  payload: [],
  filtered: [],
  selected: null,
  loading: false,
  isActive: false,
  isModal: false
}
const setContext = () => {
  addReducer('setContext', (global, dispatch, context = null) => ({ context }))
}

const toggleModal = () => {
  const { context } = global
  if (context) {
    addReducer('toggleModal', (global, dispatch, isModal) => ({ [context]: { ...global[context], isModal } }))
  } else {
    addReducer('toggleModal', (global, dispatch, modalVisible) => ({ modalVisible }))
  }
}
const toggleLoader = () => {
  const { context } = global
  if (context) {
    addReducer('toggleLoader', (global, dispatch, loading) => ({ [context]: { ...global[context], loading } }))
  } else {
    addReducer('toggleLoader', (global, dispatch, loading) => ({ loading }))
  }
}

export const appReducers = () => {
  setContext()
  toggleModal()
  toggleLoader()
}
