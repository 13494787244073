import SEO from '@americanexpress/react-seo'
import Lottie from 'lottie-react-web'
import React, { useEffect, useGlobal, useState } from 'reactn'
import Accordion from '../containers/accordion'
import { RichText } from 'prismic-reactjs'
import getColor from '../utils/getColor'
import { H1, View } from '../utils/styles'

function Main ({ item, type = 'front_page' }) {
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')

  const urlResolver = () => {
    if (website && website.content && !doc) {
      return setDoc(website.content.site)
    }
  }

  useEffect(() => {
    urlResolver()
  }, [website])

  return (
    <View
      bgc={item.data.color}
      overflow minHeight={window.innerHeight}
      style={item.data.background.url ? { backgroundSize: 'cover', background: `url(${item.data.background.url}) no-repeat center ` } : {}}>

      {item.data.background.url && item.data.background.url.endsWith('.json') &&
      <Lottie height={'100%'} options={{
        loop: true,
        autoplay: true,
        path: item.data.background.url
      }} />}

      {(doc && item.data.name.length > 0 && item.data.description.length > 0) && type === 'front_page' &&
      <SEO
        title={doc.data.name[0].text + ' || Faq'}
        description={item.data.description[0].text}
        keywords={item.tags}
        siteUrl={document.domain}
      />}

      <View pl={100} pt={50} pb={10} sm={80} pr={100}>
        {<H1 block size={2.5} bold={500} lineHeight={50} color={'#000'} pb={10} pt={10} sm={0}>
          Frequently Asked Questions
        </H1>}
      </View>

      {item.data.body.map((_sub, i) =>
        _sub.items.map((sub, index) =>
          (<div>
            <div> {sub.subtype === 'accordion' ? <Accordion item={sub} /> : null}</div>
          </div>)
        )
      )}

    </View>
  )
}
export default Main
