import React from 'react'

function Main () {
  return (
    <div>
      <h1>404</h1>
    </div>
  )
}
export default Main
