import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-date-picker'
import Colors from './website/utils/colors'
import { Modal, Row, Col } from 'react-bootstrap'
import { View, Text } from './website/utils/styles'
import { ReCaptcha } from 'react-recaptcha-google'
import fetch from 'node-fetch'

function ContactForm (props) {
  const [state, setState] = useState({ name: '', emailAddress: '', sample: '', message: '', type: '', budget: '', deadline: '' })
  const [show, setShow] = useState(false)
  const [clicked, setClicked] = useState(false)
  const captchaDemo = useRef(null)

  const handleClose = () => {
    setShow(false)
    window.location.replace('/')
  }

  const _onMount = () => {
    if (captchaDemo) {
      captchaDemo.current.reset()
      captchaDemo.current.execute()
    }
  }
  const verifyCallback = (e) => {
    handleSubmit()
  }

  const onLoadRecaptcha = () => {
    if (captchaDemo) {
      captchaDemo.current.reset()
      captchaDemo.current.execute()
    }
  }

  const messageFeecback = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title><Text size={0.7}>Mesasge Sent <span aria-label='confetti' role='img'>🎉</span> </Text></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Thanks for leaving us a feedback.</p>
        </Modal.Body>
      </Modal>
    )
  }

  const handleSubmit = e => {
    const { name, emailAddress, sample, message, type, budget, deadline } = state
    e.preventDefault()
    setClicked(true)
    const options = {
      body: JSON.stringify({
        msg: `${name + '\n' + emailAddress + '\n' + sample + '\n' + message + '\n' + type + '\n' + budget + '\n' + deadline})}`,
        emailTemplate: '',
        link: '',
        emailAddress: process.env.REACT_APP_WEBSITE_EMAIL
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }

    fetch('https://us-central1-luggr-app.cloudfunctions.net/EmailEndpoints/send/email', options)
      .then(() => { setShow(true); setClicked(false) })
      .catch(error => console.warn(error))
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    _onMount()
  }, [])

  function _renderForm () {
    return (
      <View mb={100} pr={100} pl={100} sm={80}>
        <form className={'contactform'} onSubmit={handleSubmit}>
          <Row>
            <Col className='rm-p' lg={6} xs={12}>
              <input
                type='text'
                required
                name='name'
                value={state.name}
                placeholder='Full Name'
                onChange={handleChange}
                style={inputStyles}
              />
            </Col>
            <Col lg={6} xs={12}>
              <input
                type='email'
                required
                name='emailAddress'
                value={state.emailAddress}
                placeholder='Email Address'
                onChange={handleChange}
                style={inputStyles}
              />
            </Col>
          </Row>

          <Row>
            <Col className='rm-p' lg={6} xs={12}>
              <select
                required
                name='type'
                value={state.type}
                placeholder='Explainer type'
                onChange={handleChange}
                style={inputStyles}
              >
                <option value=''>Project Type</option>
                <option>Mobile Application UI / Short Video</option>
                <option>Coportate Video</option>
                <option>2D Explainer Video</option>
                <option>3D Explainer Video</option>
              </select>
            </Col>
            <Col lg={6} xs={12}>
              <select
                required
                name='budget'
                value={state.budget}
                placeholder='Budget'
                onChange={handleChange}
                style={inputStyles}
              >
                <option value=''>Select Budget (Prices in South African Rands)</option>
                <option value='R20,000 - R40,000'>R20,000 - R40,000</option>
                <option value='R40,000 - R60,000'>R40,000 - R60,000</option>
                <option value='R60,000 - R100,000'>R60,000 - R100,000</option>
                <option value='R100,000 +'>R100,000 +</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col className='rm-p' lg={6} xs={12}>
              <input
                type='url' 
                name='sample'
                value={state.sample}
                placeholder='Have you got a video in mind? Please provide us with a url'
                onChange={handleChange}
                style={inputStyles}
              />
            </Col>
            <Col xs={12} lg={6} >
              <DatePicker
                className='input'
                dayPlaceholder='Project Deadline'
                yearPlaceholder=''
                monthPlaceholder=''
                onChange={(e) => setState({ ...state, deadline: e })}
                value={state.deadline}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} style={{ paddingLeft: 0 }}>
              <textarea
                name='message' 
                style={{ ...textareaStyles }}
                onChange={handleChange}
                placeholder='Please provide us more information about your project'
              />
            </Col>
          </Row>
          <button style={submitBtnStyles} type='submit' value='SUBMIT' >
            {clicked ? 'Please wait ...' : 'Submit'}
          </button>
        </form>
        {messageFeecback()}

        <ReCaptcha
          ref={captchaDemo}
          size='invisible'
          render='explicit'
          sitekey='6Lf2Zb8ZAAAAAOMFWQuksBDnrCenxZOo7N3Ph2WT'
          onloadCallback={onLoadRecaptcha}
          verifyCallback={verifyCallback}
        /> </View>
    )
  }

  return _renderForm()
}

const submitBtnStyles = {
  background: Colors.branding,
  border: 'none',
  width: '30%',
  height: '60px',
  fontSize: '19px',
  fontWeight: 300,
  borderRadius: '8px',
  color: 'white',
  marginTop: '20px'
}

const inputStyles = {
  fontSize: '0.8em',
  minHeight: '50px',
  width: '100%',
  outline: 'none',
  backgroundColor: '#ededed',
  borderBottom: 'none',
  borderColor: 'transparent',
  padding: '8px 10px',
  margin: '10px 0px',
  color: 'grey'
}

const textareaStyles = {
  fontSize: '0.8em',
  minHeight: '100px',
  width: '100%',
  backgroundColor: '#ededed',
  padding: '8px 10px',
  outline: 'none',
  margin: '10px 0px',
  border: 'none',
  color: 'grey',
  resize: 'none'
}

export default ContactForm
