import Lottie from 'lottie-react-web'
import { RichText } from 'prismic-reactjs'
import { Col, Image, Row } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import React, { useEffect, setGlobal, useGlobal, useRef, useState } from 'reactn'
import getColor from '../../utils/getColor'
import { H4, Link, Text, View } from '../../utils/styles'

export default function Banner ({ match }) {
  const anime = useRef(null)
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')
  const hasImage = (doc && ((doc.data.featured && doc.data.featured.url) || (doc.data.videosmall && doc.data.videosmall.url)))
  const urlResolver = () => {
    if (website && website.banner) {
      return setDoc(website.banner.results[0])
    }
  }

  useEffect(() => {
    !doc && urlResolver()
  }, [website])

  return doc ? (
    <View bgc={getColor(doc.data.inversed, true, doc.data.color)} style={doc.data.background.url ? { background: `url(${doc.data.background.url}) ${getColor(doc.data.inversed, false, doc.data.color)} no-repeat center` } : {}}>
      <Row style={{ zIndex: 2, position: 'relative' }}>
        <Col lg={hasImage ? 6 : 12} sm={12} md={12} xs={12} height={window.innerHeight} className={'rm-p'} >
          <View flex center>
            {/* -----Name and Description---- */}
            <View pl={m ? 0 : 100} pr={m ? 0 : 100} pt={100} mb={150} sm={80} center>
              <Text size={m ? 2.8 : 3.5} bold={400} color={getColor(doc.data.inversed, false, 'black')} pb={10} lineHeight={60} flex>
                {RichText.asText(doc.data.name)}</Text>
              <H4 size={1.3} lineHeight={35} color={getColor(doc.data.inversed, false, 'black')} bold={300} >
                {RichText.asText(doc.data.description)} </H4>

              <View mt={40}>
                <Row>
                  {doc.data.body.map((item, index) =>
                    (item.slice_type === 'sub'
                      ? <Col key={index} lg={5} sm={5} md={5} xs={5} className={'rm-p'}>
                        <a href={item.primary.link.url} rel='noopener noreferrer' target='_blank'>
                          <Image src={item.primary.image.url} style={{ height: m ? 40 : 60, opacity: 1 }} />
                        </a>
                      </Col> : null))}
                  {/* -----Link Button----- */}
                  {doc.data.menu && doc.data.menu.length > 0
                    ? <Col lg={5} sm={5} md={5} xs={5} className={'rm-p'}>
                      <Link href={doc.data.link.slug} bgc={getColor(doc.data.inversed, false, doc.data.color)} boxShadow={doc.data.color} color={getColor(doc.data.inversed, true, doc.data.color)}
                        pt={15} pb={15} pr={40} mt={50} pl={40} size={1.2} bold={300} buttonRadius='8px' textCenter block width={280}>
                        {RichText.asText(doc.data.menu)}&nbsp;&nbsp;<img alt='arrow' src={require('../../assets/arrow.svg')} width={30} /></Link>
                    </Col> : null}
                  <Col lg={1} sm={1} md={1} xs={2} />
                </Row>
              </View>
            </View>
          </View>
        </Col>
        {/* ---- Lottie, Video, Image ----- */}
        {hasImage ? <Col ref={anime} lg={6} sm={12} md={12} xs={12} className='rm-p overflow'>

          <View onClick={(e) =>
            setGlobal({ modalVisible: { src: process.env.REACT_APP_FEATURED_LINK, type: process.env.REACT_APP_PORTFOLIO_TYPE } })} className={'videoHolder'} />

          <View center mr={100} sm={100} overflow>
            {doc.data.featured.url && doc.data.featured.url.endsWith('.json')
              ? <Lottie height={600} options={{ loop: true, autoplay: true, path: doc.data.featured.url }} />
              : doc.data.videosmall && doc.data.videosmall.url ? <video playsInline loop autoPlay src={doc.data.videosmall.url + '#t=1, 5'} muted width='100%' poster='' height={'650px'} style={{ marginTop: -40, margin: 'auto' }} preload='metadata'><source src={doc.data.videosmall.url} type='type/mp4' /></video>
                : <Image className='shadow' src={doc.data.featured.url} alt='banner' height='100%' style={{ marginLeft: m ? '-250px' : '-50px' }} />}
          </View>
        </Col> : null}
      </Row>
    </View>
  ) : null
}
