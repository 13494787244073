import React, { Component } from 'react'
import Routes from './routes'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

class App extends Component {
  render () {
    const browserHistory = createBrowserHistory()

    return (
      <Router history={browserHistory}>
        <Routes />
      </Router>
    )
  }
}
export default App
