
import { Modal } from 'react-bootstrap'
import React, { setGlobal, useEffect, useGlobal, useState } from 'reactn'
import { View } from '../../utils/styles'
import Content from '../content'
import { sortBy } from 'lodash'

function App () {
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')
  const [modalVisible] = useGlobal('modalVisible')

  const urlResolver = () => {
    if (website) {
      setDoc(website.content)
    }
  }

  const Media = () => {
    return modalVisible ? (
      <Modal show={modalVisible != null} className='media' on>
        <Modal.Header closeButton onClick={(e) => setGlobal({ modalVisible: null })}>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalVisible.type === 'img' && <img src={modalVisible.src}
            width={'100%'}
            height={'100%'} />}
          {modalVisible.type === 'video' &&
          <div class='videowrapper' style={{ overflow: 'hidden' }}>
            <iframe src={modalVisible.src} rel={0} loop={1} autoplay={1}
              frameborder='0' allowfullscreen
              width={'100%'}
              height={480} />
          </div>}
        </Modal.Body>
      </Modal>
    ) : null
  }

  useEffect(() => {
    !doc && urlResolver()
  }, [website])

  return doc ? (
    <View bg='light' overflow>
      {Media()}
      {sortBy(doc.results, [(o) => Number(o.data.position)]).map((item, index) => <Content key={index} item={item} type='front_page' />)}

    </View>
  ) : null
}

export default App
