import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { initGlobal } from './services/store'
import { toast } from 'react-toastify'
import Favicon from 'react-favicon'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './index.css'

initGlobal()

toast.configure({
  autoClose: 3000,
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
  bodyClassName: 'toast'
})

ReactDOM.render(<div> <Favicon url='./favicon.ico' /><App /></div>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
