import { RichText } from 'prismic-reactjs'
import { Nav, Navbar } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import Lottie from 'lottie-react-web'
import React, { useEffect, useGlobal, useState } from 'reactn'
import { View } from '../utils/styles'

function Header (props) {
  const [doc, setDoc] = useState({ website: null, menu: null })
  const [website] = useGlobal('website')

  const urlResolver = () => {
    if (website && website.site) {
      return setDoc({ website: website.site.results[0], menu: website.content.results })
    }
  }

  useEffect(() => {
    !doc.website && urlResolver()
  }, [website])

  return doc.website ? (
    <Navbar expand='lg' collapseOnSelect sticky='top' className='justify-content-between bshadow bgWhite rm-pv'>
      <Navbar.Brand href='/'>
        <View pl={80} sm={80} >
          {doc.website.data.logo.url.endsWith('.json')
            ? <Lottie width={m ? 200 : 300} options={{
              loop: false,
              autoplay: true,
              path: doc.website.data.logo.url }} />
            : <img src={doc.website.data.logo.url} alt='logo' style={{ height: m ? '35px' : '60px' }} />}
        </View>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />

      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto nav-links bold mr-nav'>
          {doc.menu.map((sub, index) =>
            sub.data.type === 'main_menu'
              ? <Nav.Link href={sub.slugs[0]}>{RichText.asText(sub.data.menu)}</Nav.Link>
              : null
          )}
          {doc.menu.map((sub, index) =>
            sub.data.type === 'menu'
              ? <Nav.Link href={sub.slugs[0]}>{RichText.asText(sub.data.menu)}</Nav.Link>
              : null
          )}
          {doc.menu.map((sub, index) =>
            sub.data.type === 'portfolio'
              ? <Nav.Link href={sub.slugs[0]}>{RichText.asText(sub.data.menu)}</Nav.Link>
              : null
          )}
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  ) : null
}
export default Header
