import { RichText } from 'prismic-reactjs'
import { Col } from 'react-bootstrap'
import React from 'reactn'
import Lottie from 'lottie-react-web'

import arrow from '../assets/forward.json'
import getColor from '../utils/getColor'
import { H1, Text, View } from '../utils/styles'

function Main ({ item }) {
  return (
    <Col lg={4} sm={12} md={6} xs={12}>
      <View bgc={item.subcolor} className='fshadow' mt={30} pb={30} center id='card' overflow >
        <View id='inner' >
          <img src={item.subimage.url} width={'100%'} height={'100%'} />
          <View p={30}>
            {<H1 size={1.8} bold={500} color={getColor(item.subinversed, false, item.subcolor)} mt={10} pb={10} >
              {RichText.asText(item.subname)}</H1>}
            {item.subdescription.length > 0 &&
            <Text id='textGrid' size={0.5} bold={300} lineHeight={35} pb={10} mt={5} color={getColor(item.subinversed)} >
              {RichText.render(item.subdescription)}
            </Text>}
          </View>
        </View>
      </View>
    </Col>
  )
}
export default Main
