import Lottie from 'lottie-react-web'
import { RichText } from 'prismic-reactjs'
import React from 'reactn'
import getColor from '../utils/getColor'
import { H1, Link, View } from '../utils/styles'

function InfoButton ({ item }) {
  return (
    <View bgc={item.subcolor} overflow>
      {item.subbackground.url && item.subbackground.url.endsWith('.json') &&
        <View style={{ marginBottom: -500, zIndex: 0 }} relative >
          <Lottie height={600} options={{
            loop: true,
            autoplay: true,
            path: item.subbackground.url
          }} /></View>}
      <View zIndex={7093} relative center style={item.subbackground.url ? { background: `url(${item.subbackground.url}) no-repeat center` } : {}}>
        <View className='center' center pt={item.subname.length > 0 ? 150 : 0} pb={item.subname.length > 0 ? 150 : 0} >
          <H1 textCenter size={2.5} bold={500} color={getColor(item.subinversed)} pb={10}>
            {RichText.asText(item.subname)}</H1>
          <H1 className='center' textCenter size={2} bold={300} color='white' pb={40} mt={5}>
            <RichText render={item.subdescription} /></H1>
          {item.submenu.length > 0 && <View center>
            <Link className='buttonCustom' href={item.sublink.url} bgc={getColor(item.subinversed)} color={getColor(item.subinversed, true, item.subcolor)} p={20} pr={50} pl={50} mb={50} size={1.5} bold={400} textCenter block center buttonRadius>{RichText.asText(item.submenu)}</Link>
          </View>}
        </View>
      </View>
    </View>
  )
}
export default InfoButton
