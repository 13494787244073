import { motion, useTransform, useViewportScroll } from 'framer-motion'
import { find } from 'lodash'
import Lottie from 'lottie-react-web'
import { RichText } from 'prismic-reactjs'
import { Col } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import React, { setGlobal, useState } from 'reactn'
import getColor from '../utils/getColor'
import { H1, Link, Text, View } from '../utils/styles'
import { useInView } from 'react-hook-inview'

function Main ({ item, tags }) {
  const [lottieOn, setLottieOn] = useState(false)
  const [ref, isVisible] = useInView()

  // const { scrollY } = useViewportScroll()
  // const opacity = useTransform(scrollY, [0, 300], [0, 1])
  // const y1 = useTransform(scrollY, [0, 200], [-100, 50])

  return (
    <Col lg={6} sm={12} md={6} xs={12} className={'rm-p'}>

      {item.subname.length > 0 && item.subsetting !== 'details_hover' &&
      <View style={{ backgroundColor: item.subcolor }} center flex p={item.subsetting !== 'center' ? 100 : 0} sm={50} height={m ? '600' : '650'}>
        <View center>

          {/* -----Lottie Background----- */}
          {item.subbackground && item.subbackground.url &&
          <Lottie height={500} options={{
            loop: true,
            autoplay: true,
            path: item.subbackground.url }} />}
          {<H1 size={2.3} bold={600} lineHeight={50} color={getColor(item.subinversed)} pb={20} pt={40}>
            {RichText.asText(item.subname)}
          </H1>}
          {item.subdescription.length > 0 &&
          <Text id='textGrid' size={1} bold={300} lineHeight={35} pb={40} mt={5} mb={50} color={getColor(item.subinversed)} >
            {RichText.render(item.subdescription)}
          </Text>}

          {/* ---- Link ----- */}
          {item.sublink && item.sublink.url &&
          <View>
            <Link href={item.sublink.url} bgc={getColor(item.subinversed)} color={getColor(item.subinversed, true, item.subcolor)} p={20} pr={50} mt={50} pl={50} size={1.5} bold={400} textCenter block buttonRadius>{RichText.asText(item.submenu)}</Link>
          </View>}
        </View> </View>}

      { (item.subimage.url || item.subvideosmall.url) &&
        <motion.div >

          <View relative center bgc={item.subcolor} flex overflow className='containhover center'>
            {item.subvideosmall.url && <View className={'videoPlayer'} style={{ left: window.innerWidth / (m ? 1 : 3), top: window.innerHeight / 4 }} />}
            {/* ---- Hover details ----- */}
            {item.subsetting === 'details_hover' &&
              <View onClick={(e) =>
                setGlobal({ modalVisible: { src: item.sublink.url, type: process.env.REACT_APP_PORTFOLIO_TYPE } })} className='middleBg' center bgc={item.subcolor + 80} pt={100} pl={100} pr={100} style={{ height: '100%', width: '100%' }}>
                {<H1 size={2} bold={500} lineHeight={50} color={'white'} pb={10} pt={50} sm={0}>
                  {RichText.asText(item.subname)}
                </H1>}
                {item.subdescription.length > 0 &&
                <Text id='textGrid' color='white' size={1} bold={400} lineHeight={35} pb={50} mt={5} mb={50} >
                  {RichText.render(item.subdescription)}
                </Text>}
              </View> }
            {/* ---- Lottie, Video, Image ----- */}
            {item.subimage.url && item.subimage.url.endsWith('.json')
              ? <div ref={ref}><Lottie height={500} options={{ loop: lottieOn, autoplay: lottieOn, path: item.subimage.url }} /></div>
              : item.subvideosmall.url && item.subvideosmall.url.endsWith('.mp4')
                ? <video playsInline loop autoPlay muted src={item.subvideosmall.url + '#t=30,45'} height='650' style={{ zIndex: 0 }} />
                : item.subvideosmall.url && <iframe src={item.subvideosmall.url} rel={0} loop={1} autoplay={1}
                  frameborder='0' allowfullscreen width={'100%'} height={650} />
                  ? <img src={item.subimage.url} alt={item.subimage.alt} style={{ margin: 'auto', alignSelf: 'center' }}
                    className={item.subsetting === 'details_hover' ? 'imageBg' : ''}
                    height={item.subsetting === 'center' || m ? '550px' : '650px'} /> : null}
          </View>
        </motion.div>
      }
    </Col>
  )
}
export default Main
