import SEO from '@americanexpress/react-seo'
import { find } from 'lodash'
import Lottie from 'lottie-react-web'
import { RichText } from 'prismic-reactjs'
import { Row } from 'react-bootstrap'
import React, { useEffect, useGlobal, useState } from 'reactn'
import ContactForm from '../../contactForm'
import Accordion from '../containers/accordion'
import Button from '../containers/button'
import Exact from '../containers/exact'
import Grid from '../containers/grid'
import GridPlain from '../containers/gridPlain'
import GridShadow from '../containers/gridShadow'
import InfoButton from '../containers/infoButton'
import getColor from '../utils/getColor'
import { H1, Text, View } from '../utils/styles'

function Main ({ item, type = 'front_page' }) {
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')

  const urlResolver = () => {
    if (website && website.content && !doc) {
      return setDoc(website.content.site)
    }
  }

  useEffect(() => {
    urlResolver()
  }, [website])

  return item.data.type === type || type === null ? (
    <View
      bgc={item.data.color}
      overflow minHeight={type === 'front_page' ? null : window.innerHeight / 2}
      style={item.data.background.url ? { backgroundSize: 'cover', background: `url(${item.data.background.url}) no-repeat center ` } : { marginBottom: type === 'front_page' ? '50px' : null }}>

      {item.data.background.url && item.data.background.url.endsWith('.json') &&
      <Lottie height={'100%'} options={{
        loop: true,
        autoplay: true,
        path: item.data.background.url }} />}

      {(doc && item.data.name.length > 0 && item.data.description.length > 0) && type !== 'front_page' &&
      <SEO
        title={doc.data.name[0].text + ' || ' + item.data.name[0].text}
        description={item.data.description[0].text}
        keywords={item.tags}
        siteUrl={document.domain}
      />}

      {item.data.name.length !== 0 && item.data.name[0].text &&
      <View pl={100} pt={50} pb={10} sm={80} pr={100}>
        {<H1 block size={2.5} bold={500} lineHeight={50} color={getColor(item.data.inversed, true, 'dark')} pb={10} pt={10} sm={0}>
          {RichText.asText(item.data.name)}
        </H1>}
        {item.data.description.length > 0 &&
        <Text id='textGrid' color={getColor(item.data.inversed, true, 'dark')} size={1.5} bold={300} lineHeight={35} pb={50} mt={5} mb={50} >
          {RichText.render(item.data.description)}
        </Text>}
      </View>}

      {item.data.body.map((_sub, i) =>
        _sub.items.map((sub, index) =>
          (<div>
            <div> {sub.subtype === 'exact' ? <Exact item={sub} /> : null }</div>
          </div>)
        )
      )}

      {item.data.body.map((_sub, i) =>
        <View overflow>
          <Row key={i}>
            {_sub.items.map((sub, index) => (
              sub.subtype === 'grid' ? <Grid item={sub} tags={item.tags} /> : null
            ))}
          </Row>
        </View>
      )}

      {item.data.body.map((_sub, i) =>
        _sub.items.map((sub, index) =>
          (
            <div> {sub.subtype === 'contact' ? <ContactForm item={sub} /> : null }</div>
          )
        )
      )}

      {item.data.body.map((_sub, i) =>
        <div>
          {find(_sub.items, (e) => e.subtype === 'grid_plain') && <View pl={100} pr={100} pt={0} pb={50} sm={80} smpt={10} center>
            <Row key={i}>
              {_sub.items.map((sub, index) => (
                sub.subtype === 'grid_plain' ? <GridPlain numberOfItems={12 / _sub.items.length} item={sub} /> : null
              ))}
            </Row>
          </View>}
        </div>
      )}

      {item.data.body.map((_sub, i) =>
        _sub.items.map((sub, index) =>
          (<div>
            {sub.subtype === 'accordion' && index === 0 && <View mb={50} />}
            <div> {sub.subtype === 'info_button' ? <InfoButton item={sub} /> : null }</div>
            <div> {sub.subtype === 'button' ? <Button item={sub} /> : null }</div>
            <div> {sub.subtype === 'accordion' ? <Accordion item={sub} /> : null }</div>
          </div>)
        )
      )}

      {item.data.body.map((_sub, i) =>
        <div>
          {find(_sub.items, (e) => e.subtype === 'grid_shadow') && <View pl={100} pr={100} pt={50} mb={50} sm={80} smpt={10} center>
            <Row key={i}>
              {_sub.items.map((sub, index) => (
                sub.subtype === 'grid_shadow' ? <GridShadow item={sub} /> : null
              ))}
            </Row>
          </View>}
        </div>

      )}

    </View>
  ) : null
}
export default Main
