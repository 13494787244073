import { RichText } from 'prismic-reactjs'
import React from 'reactn'
import { H1, Text, View } from '../utils/styles'

function Main ({ item }) {
  return (
    <View overflow>
      <View >
        <View className='center' center m={100} mb={20} sm={50} block>
          <H1 textCenter size={2.5} bold={500} color='dark' pb={0} block>
            {RichText.asText(item.subname)}</H1>
          <Text textCenter size={1.5} bold={300} color='dark' block pb={0}>
            {RichText.asText(item.subdescription)}</Text>
        </View>
      </View>
    </View>
  )
}
export default Main
