import React, { useGlobal, setGlobal } from 'reactn'
import { Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './website/containers/main'
import Portfolio from './website/containers/sections/portfolio'
import Faq from './website/containers/sections/faq'
import Blank from './website/app-redirect'
import ErrorPage from './website/containers/404'
import Content from './website/containers/sections/content'
import Prismic from '@prismicio/client'
import { Client } from './services/api'
import moment from 'moment'

export default function Routes ({ match }) {
  const [website] = useGlobal('website')
  const [expiry] = useGlobal('expiry')

  function hasExpired () {
    if (website && expiry && moment().isAfter(expiry)) {
      fetchData()
    } else if (!website) {
      fetchData()
    }
    return false
  }

  const fetchData = async () => {
    let _website
    _website = {
      site: await Client.query(
        Prismic.Predicates.at('document.type', 'site')
      ),
      banner: await Client.query(
        Prismic.Predicates.at('document.type', 'banner')
      ),
      blog: await Client.query(
        Prismic.Predicates.at('document.type', 'blog')
      ),
      content: await Client.query(
        Prismic.Predicates.at('document.type', 'content')
      ) }
    if (_website) {
      setGlobal({ website: _website, expiry: moment().add(1, 'hour').format('LLL') })
    }
  }

  React.useEffect(() => {
    hasExpired()
  }, [])

  return (
    <div>
      <Switch>
        <Route component={HomePage} exact path='/' />
        <Route component={Blank} path='/app/:id' />
        <Route component={Faq} path='/faq' />
        <Route component={Portfolio} path='/portfolio' />
        <Route component={Content} path='/:uid' />
        <Route component={ErrorPage} path='/404' />
        <Redirect to='/' />
      </Switch>
    </div>
  )
}
