import { addCallback, setGlobal } from 'reactn'
import { isEqual } from 'lodash'
import { instantiateReducers } from './reducers'

export const initGlobal = () => {
  const initState = (name, state) => {
    return JSON.parse(window.localStorage.getItem(name)) || state
  }
  setGlobal({
    auth: initState('auth', false),
    website: initState('website', null),
    expiry: initState('expiry', null),
    modalVisible: null,
    loading: false,
    status: null,
    context: null,
    event: null
  })

  // persistGlobal(['website', 'expiry'])
  instantiateReducers()
}

export default function persistGlobal (args) {
  addCallback(global => {
    args.forEach(key => {
      if (!isEqual(global[key], window.localStorage.getItem(key))) {
        window.localStorage.setItem(key, JSON.stringify(global[key]))
      }
    })
    return null
  })
}
