import { Container } from 'react-bootstrap'
// import { useHistory } from 'react-router-dom'
import React, { useEffect, useGlobal, useState } from 'reactn'
import { View } from '../../utils/styles'
import Faq from '../faq'
import Templates from '../templates'

function Main ({ match }) {
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')
  // const history = useHistory()

  const urlResolver = () => {
    if (website && website.content) {
      for (var id in website.content.results) {
        if (website.content.results[id].slugs.includes('frequently-asked-questions')) {
          return setDoc(website.content.results[id])
        } else {
          // return history.push('/')
        }
      }
    }
  }
  useEffect(() => {
    !doc && urlResolver()
  }, [website])

  return (
    <Templates>
      <Container fluid className='rm-p'>
        <View overflow>
          {doc ? <Faq item={doc} type={'front_page'} />
            : <View bgc={process.env.REACT_APP_WEBSITE_COLOR} minHeight={window.innerHeight} center flex>
              <img height={150} src={require('../../assets/loader.svg')} alt='loader' />
            </View>}
        </View>
      </Container>
    </Templates>
  )
}
export default Main
