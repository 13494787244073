import React, { useState, useEffect, useGlobal } from 'reactn'
import Header from '../components/header'
// import Content from './sections/content'
import Footer from './sections/footer'
import { View, Link, Text } from '../utils/styles'
import Banner from 'react-js-banner'
import CookieConsent from 'react-cookie-consent-notification'

var date = new Date(Date.now())
var year = date.getFullYear()

function Main (props) {
  const [doc, setDoc] = useState(null)
  const [website] = useGlobal('website')

  const urlResolver = () => {
    if (website && website.site) {
      return setDoc({ website: website.site.results[0], menu: website.content.results })
    }
  }

  useEffect(() => {
    !doc && urlResolver()
  }, [website])

  const checkStatus = (status) => {
    if (status) {
    }
  }

  return (
    <View m={0}>
      {process.env.REACT_APP_SHOW_BANNER &&
      <Banner showBanner>
        <View>Stay informed! Visit the SA department of health's website for COVID-19 updates: <a rel='noopener noreferrer' target='_blank' href='https://www.sacoronavirus.co.za'>www.sacoronavirus.co.za</a>
        </View>
      </Banner>}
      <Header {...props} />
      {props.children}
      <Footer />
      <View w={200}>
        <CookieConsent
          background={'#fff'}
          bottomPosition
          buttonText={'I Agree'}
          buttonBackground={'#ccc'}
          buttonColor={'#ccc'}
          buttonFontSize={12}
          color={'#000'}
          consentFunction={checkStatus}
          padding={30}
        >
          <Text size={0.8}>
        We use cookies on this site to personalise content and enhance your user experience. By clicking or tapping 'I Agree', you are giving your consent for us to set cookies.
          </Text>
        </CookieConsent></View>

      <View center p={10}>
        <Text bgc='#e8e8e8' size={0.7} p={10} textCenter block height={40} lineHeight={40}>© {year} {doc ? doc.website.data.name[0].text : ''} <Link bold={500} color={'#A2A2A3'} href='http://www.sciphergfx.com' target='_blank'>Website Design, Sciphergfx Creative Solutions</Link></Text>
      </View>
    </View>
  )
}
export default Main
