import Lottie from 'lottie-react-web'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import { RichText } from 'prismic-reactjs'
import { Col } from 'react-bootstrap'
import React, { useState } from 'reactn'
import getColor from '../utils/getColor'
import { H1, Link, Text, View } from '../utils/styles'
import { useInView } from 'react-hook-inview'

function Main ({ item, numberOfItems }) {
  const [lottieOn, setLottieOn] = useState(false)
  const { scrollY } = useViewportScroll()
  const [ref, isVisible] = useInView()
  const opacity = useTransform(scrollY, [0, 300], [0, 1])
  const y = useTransform(scrollY, [0, 300], [0, 0])

  return (
    <Col ref={ref} lg={4} md={6} sm={12} xs={12}>
      {<motion.div style={isVisible ? { opacity, y } : {}}>
        <View pt={20} mt={30} className='gridPlain' style={{ backgroundColor: getColor(item.subinversed, true, item.subcolor) }}>
          <H1 size={1.8} textCenter bold={400}
            color={getColor(item.subinversed, false, item.subcolor)}
            pb={10} pt={20}>
            {RichText.asText(item.subname)}
          </H1>

          {item.subbackground && item.subbackground.url
            ? <div onMouseEnter={() => setLottieOn(lottieOn)}><Lottie height={200} options={{ loop: isVisible, autoplay: isVisible, path: item.subbackground.url }} /></div>
            : <img src={item.subimage.url} height={'200'} style={{ display: 'block', border: 'none', margin: 'auto' }} />}
          <Text color={getColor(item.subinversed, false, item.subcolor)} textCenter bold={300} size={1.5} block style={{ height: 120 }}>
            {RichText.render(item.subdescription)}
          </Text>
          {/* -----Link Button----- */}
          {item.submenu.length > 0 && item.submenu[0].text !== '' && item.sublink &&
          <View>
            <Link center href={item.sublink.url} bgc={getColor(item.subinversed, false, item.subcolor)} boxShadow={item.subcolor} color={getColor(item.subinversed, true, '#000')} pt={15} pb={15} pr={40} mt={50} pl={40} size={1.2} bold={300} buttonRadius='8px' textCenter block width={180}> {RichText.asText(item.submenu)}</Link> </View>}
        </View>
      </motion.div >}
    </Col>
  )
}
export default Main
