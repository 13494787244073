import React, { useGlobal } from 'reactn'
import Templates from './templates'
import App from '../containers/sections/app'
import Banner from './sections/banner'
import { View } from '../utils/styles'

function Main () {
  const [website] = useGlobal('website')

  return website ? (
    <Templates>
      <Banner />
      <App />
    </Templates>
  ) : <View bgc={process.env.REACT_APP_WEBSITE_COLOR} minHeight={window.innerHeight} center flex>
    <img height={150} src={require('../assets/loader.svg')} alt='loader' />
  </View>
}
export default Main
